import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { Col, Container, Row } from "reactstrap";

// img
import discover from "../../img/discover.png"
import design from "../../img/design.png"
import build from "../../img/build.png"
import deliver from "../../img/deliver.png"

const Step = () => {
    return (
        <>
            <h2 className="hero2-title w-100 h-100 m-0 text-center py-5" style={{ color: "#132028", fontWeight: "600" }}>
                We ease the <span style={{ color: "#0777AB" }}>software</span> development journey.
            </h2>

            <Container className="pb-5 mb-5">
    <Row>
        <Col md={6} lg={3} className="d-flex mt-4 flex-column h-100">
            <div className="p-3 text-center flex-grow-1">
                <img src={discover} alt="discover" className="img-fluid" />
                <p className="pt-5" style={{ color: "#1c1c1c" }}>
                    We build brands by exploring new ideas and doing deep research to question assumptions along the way.
                </p>
            </div>
        </Col>
        <Col md={6} lg={3} className="d-flex mt-4 flex-column h-100">
            <div className="p-3 text-center flex-grow-1">
                <img src={design} alt="design" className="img-fluid" />
                <p className="pt-5" style={{ color: "#1c1c1c" }}>
                    Our design focus is on simplicity. We love creating unique things that are easy for people to use.
                </p>
            </div>
        </Col>
        <Col md={6} lg={3} className="d-flex mt-4 flex-column h-100">
            <div className="p-3 text-center flex-grow-1">
                <img src={build} alt="build" className="img-fluid" />
                <p className="pt-5" style={{ color: "#1c1c1c" }}>
                    We use modern technology to build smart, flexible, and scalable solutions that drive business growth.
                </p>
            </div>
        </Col>
        <Col md={6} lg={3} className="d-flex mt-4 flex-column h-100">
            <div className="p-3 text-center flex-grow-1">
                <img src={deliver} alt="deliver" className="img-fluid" />
                <p className="pt-5" style={{ color: "#1c1c1c" }}>
                    We continuously refine our work and process, always seeking ways to improve.
                </p>
            </div>
        </Col>
    </Row>
</Container>

        </>
    );
};

export default Step;
