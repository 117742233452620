import React, { useState, useEffect, lazy, Suspense } from 'react';
import Loading from '../Component/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faPersonCirclePlus } from '@fortawesome/free-solid-svg-icons';
import { useParams } from 'react-router-dom';
import SEOHelmet from '../Component/SEOHelmet';

const Header = lazy(() => import('../Component/Header'));
const Footer = lazy(() => import('../Component/Footer'));
const Touch = lazy(() => import('../Component/Home/Touch'));
const ApplyNow = lazy(() => import('../Component/Home/ApplyNow'));
const WhatNext = lazy(() => import('../Component/Home/WhatNext'));

const ApplyNowPage = () => {
    const [data, setData] = useState();
    const [isScrollTopVisible, setIsScrollTopVisible] = useState(false);
    const { id } = useParams();


    const handleClick = () => {
        window.location.href = '/hiring';
    };

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        const storedData = sessionStorage.getItem('hiringStatus');
        if (storedData) {
            setData(JSON.parse(storedData));
        }

        const handleScroll = () => {
            if (window.scrollY > 200) {
                setIsScrollTopVisible(true);
            } else {
                setIsScrollTopVisible(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div>

            <SEOHelmet
                title="Apply Now | Plexus Technology"
                description="Ready to make an impact? Apply now to become part of Plexus Technology's innovative team. Unlock your career potential with exciting job openings in IT, software development, and more."
                keywords="Apply now , Job application , Submit resume , Apply today , Career application , Join Plexus , Apply for jobs , Submit your application , IT job apply , Apply here , Start now , Job opening , Apply for tech , Submit resume now , Apply immediately , Job submission , Tech career apply , Start your application , Apply today , Join us now"
                ogTitle="Apply Now | Plexus Technology"
                ogDescription="Ready to make an impact? Apply now to become part of Plexus Technology's innovative team. Unlock your career potential with exciting job openings in IT, software development, and more."
                ogImage="https://plexus-technology.in/static/media/logo.589291ad7a3760919228.png"
                twitterCard="summary_large_image"
            />

            <Suspense fallback={<Loading />} >
                <Header />
                <ApplyNow params={id} />
                <WhatNext />
                <Touch />
                <Footer />
            </Suspense>
            {data === "true" && (
                <div className="fixed-button">
                    <button className='animation' style={{ fontSize: "18px" }} onClick={handleClick}>
                        <FontAwesomeIcon icon={faPersonCirclePlus} className="fs-5 pe-2" />
                        We're Hiring
                    </button>
                </div>
            )}

            {isScrollTopVisible && (
                <p className='fixed-top-btn cursor' onClick={scrollToTop}>
                    <FontAwesomeIcon icon={faArrowUp} className='px-2' />
                </p>
            )}
        </div>
    );
};

export default ApplyNowPage;