// components/SEOHelmet.js
import React from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';

const SEOHelmet = ({
    title,
    description,
    keywords,
    ogTitle,
    ogDescription,
    ogImage,
    twitterCard
}) => {
    const defaultTitle = "Plexus Technology - Mobile And Game App Development Company";
    const defaultDescription = "At Plexus Technology, work is more than a job — it’s a calling. To build. To design. To code. To consult. To invent and collaborate. Join us today.";
    const defaultImage = "https://plexus-technology.in/static/media/logo.589291ad7a3760919228.png"; // Change to your default image URL
    const currentUrl = window.location.href;

    return (
        <HelmetProvider>
            <Helmet>
                {/* Title */}
                <title>{title || defaultTitle}</title>

                {/* Meta Description */}
                <meta name="description" content={description || defaultDescription} />

                {/* Meta Keywords */}
                <meta name="keywords" content={keywords || "Plexus Technology IT solutions , plexus services , plexus IT company , plexus technology , IT, services, technology"} />

                {/* Viewport */}
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />

                {/* Canonical URL */}
                <link rel="canonical" href={currentUrl} />

                {/* Open Graph Meta Tags */}
                <meta property="og:title" content={ogTitle || title || defaultTitle} />
                <meta property="og:description" content={ogDescription || description || defaultDescription} />
                <meta property="og:image" content={ogImage || defaultImage} />
                <meta property="og:url" content={currentUrl} />
                <meta property="og:type" content="website" />

                {/* Twitter Card Meta Tags */}
                <meta name="twitter:card" content={twitterCard || "summary_large_image"} />
                <meta name="twitter:title" content={ogTitle || title || defaultTitle} />
                <meta name="twitter:description" content={ogDescription || description || defaultDescription} />
                <meta name="twitter:image" content={ogImage || defaultImage} />
            </Helmet>
        </HelmetProvider>
    );
};

export default SEOHelmet;
