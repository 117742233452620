import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Col, Container, Row } from "reactstrap";

const Image = () => {
    const [data, setData] = useState();

    const getData = () => {
        axios.get('https://plexus-technology.in/api/culture/read2')
            .then((res) => {
                setData(res.data.data);
                // setLoading(false);
            })
            .catch((err) => {
                console.error(err);
                toast.error("Failed to fetch data.");
                // setLoading(false);
            });
    };

    useEffect(() => {
        getData();
    }, []);


    return (
        <>
            <section className="pb-5 mb-2">
            <div className="culture-bg mt-1 d-flex align-items-center justify-content-center" style={{ height: "280px" }}>
                <h1 className="hero-title w-100 h-100 m-0 text-center text-white" style={{  background: 'rgba(0, 0, 0, 0.45)', lineHeight: "280px" ,color: "#132028", fontWeight: "600" }}>Culture</h1>
            </div>

                <Container className="pt-5 mt-5">
                    <Row className="rounded-4" style={{ background: "#EBF1FA" }}>
                        {data && data.length > 0 ? (
                            <>
                                <Col
                                    lg={6}
                                    className="d-flex align-items-center justify-content-center custom-height"
                                >
                                    <div className="text-start p-4">
                                        <h2 className="hero2-title m-0 ps-2" style={{ color: "#132028", fontWeight: "600" }}>
                                            Life at <span style={{ color: "#0777AB" }}>Plexus :</span>
                                            <p>Innovation, Growth, and Collaboration</p>
                                        </h2>
                                        <p className="container space my-3">
                                            At Plexus Technology, we foster a joyful, supportive workplace where employees feel valued. We celebrate festivals like Holi, Diwali, and Navratri, enjoy yearly trips, and host fun activities, making work a fulfilling journey.
                                        </p>
                                    </div>
                                </Col>

                                <Col lg={6} style={{ height: "650px", overflow: "hidden" }}>
                                    <Row>
                                        <Col xs={4} className="px-2">
                                            <div className="slider-container-culture2">
                                                <div className="slider-content-culture2">
                                                    {[...data, ...data].map((imageSet, index) => (
                                                        <div key={index} className="d-flex flex-column gap-3 pb-3">
                                                            {imageSet.map((image, imgIndex) => (
                                                                <img
                                                                    key={imgIndex}
                                                                    src={`https://plexus-technology.in/api/public/images/culture/${image}`}
                                                                    alt={`Image ${imgIndex}`}
                                                                    className="w-100 object-fit-cover object-position-top rounded-3"
                                                                    style={{ height: "245px" }}
                                                                />
                                                            ))}
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xs={4} className="px-2">
                                            <div className="slider-container-culture3">
                                                <div className="slider-content-culture3">
                                                    {[...data.reverse(), ...data].map((imageSet, index) => (
                                                        <div key={index} className="d-flex flex-column gap-3 pb-3">
                                                            {imageSet.map((image, imgIndex) => (
                                                                <img
                                                                    key={imgIndex}
                                                                    src={`https://plexus-technology.in/api/public/images/culture/${image}`}
                                                                    alt={`Image ${imgIndex}`}
                                                                    className="w-100 object-fit-cover object-position-top rounded-3"
                                                                    style={{ height: "245px" }}
                                                                />
                                                            ))}
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </Col>

                                        <Col xs={4} className="px-2">
                                            <div className="slider-container-culture2">
                                                <div className="slider-content-culture2">
                                                    {[...data, ...data].map((imageSet, index) => (
                                                        <div key={index} className="d-flex flex-column gap-3 pb-3">
                                                            {imageSet.map((image, imgIndex) => (
                                                                <img
                                                                    key={imgIndex}
                                                                    src={`https://plexus-technology.in/api/public/images/culture/${image}`}
                                                                    alt={`Image ${imgIndex}`}
                                                                    className="w-100 object-fit-cover object-position-top rounded-3"
                                                                    style={{ height: "245px" }}
                                                                />
                                                            ))}
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </>
                        ) : (
                            <Col xs={12} style={{ height: "650px", overflow: "hidden" }}>
                                <p className="text-center p-5"></p>
                            </Col>
                        )}
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default Image;
